.recipes-slider-container {
  background-image: url("../../../../assets/images/bg_recipes.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  height: auto;
  justify-content: center;
  padding-top: 15px;
  position: relative;
  flex-direction: column;
}
.btn-vermas img {
  width: 200px;
}
.recipes-slider {
  width: 100%;
  z-index: 20;
  display: block;
  align-self: center;
}
.recipes .slick-slide.slick-center img {
  transform: scale(1.2); /* resize the central element */
  opacity: 1; /* central element with full opacity */
  border: 8px solid #ff7a22;
}

.recipes .slick-list {
  height: 300px;
}

.recipes .slick-list .slick-slide .caption-slide {
  font-size: 14px;
  display: none;
}
.recipes .slick-list .slick-slide.slick-center .caption-slide {
  display: block;
  margin-top: 20px;
  text-transform: uppercase;
  font-weight: bold;
}
.recipes-slider-container .parallax-image-container.top {
  z-index: 10;
  width: 100%;
  text-align: center;
  top: 70px;
}
.recipes-slider-container .parallax-image-container.right {
  top: 0;
  right: 0;
}
.recipes-slider-container .triangle-top {
  right: 30px;
}

.recipes-slider-container .triangle-left {
  transform: rotate(25deg);
  left: 10px;
  width: 70px;
}

.recipes-slider-container .triangle-right {
  transform: rotate(90deg);
  width: 50px;
}

/* Small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
  .recipes .slick-slide.slick-center img {
    transform: scale(1);
  }
  .recipes .slick-list .slick-slide .caption-slide {
    font-size: 16px;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .recipes-slider-container {
    background-size: cover;
  }
  .recipes .slick-slide.slick-center img {
    transform: scale(0.8);
  }
  .recipes .slick-list {
    height: 400px;
  }
  .recipes .slick-list .slick-slide .caption-slide {
    font-size: 15px;
  }
  .recipes .slick-list .slick-slide.slick-center .caption-slide {
    margin-top: 0;
  }
  .recipes-slider-container .triangle-left {
    width: 150px;
  }

  .recipes-slider-container .triangle-right {
    width: 120px;
  }
}

@media only screen and (min-width: 1000px) {
  .recipes-slider-container {
    padding-top: 0;
  }
  .recipes-slider {
    width: 95%;
  }
  .recipes .slick-slide.slick-center img {
    transform: scale(1.3);
  }

  .recipes .slick-list {
    height: 350px;
  }
  .recipes .next-arrow {
    right: 0;
  }
  .recipes .prev-arrow {
    left: 0;
  }
  .recipes .slick-list .slick-slide.slick-center .caption-slide {
    margin-top: 50px;
  }
  .recipes-slider-container .parallax-image-container.top {
    z-index: 10;
    width: 100%;
    text-align: center;
  }
  .recipes-slider-container .triangle-top {
    right: 30px;
  }

  .recipes-slider-container .triangle-left {
    transform: rotate(25deg);
    left: 10px;
    width: 250px;
  }

  .recipes-slider-container .triangle-right {
    transform: rotate(90deg);
    right: 30px;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .recipes-slider {
    width: 80%;
  }
}

@media only screen and (min-width: 1400px) {
  .recipes-slider-container {
    background-size: 100%;
  }
  .recipes .slick-list {
    height: 330px;
  }
}

@media only screen and (min-width: 1600px) {
}
