.footer-wrapper {
  height: 925px;
  position: relative;
}
.header-container {
  position: relative;
  z-index: 1;
}

.header-container .divider-container {
  bottom: -15px;
}

@media only screen and (min-width: 425px) {
  .header-container .divider-container {
    bottom: -35px;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 1000px) {
  .footer-wrapper {
    height: 530px;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1400px) {
  .footer-wrapper {
    height: 590px;
  }
  .header-container .divider-container {
    bottom: -50px;
  }
}

@media only screen and (min-width: 1600px) {
}
