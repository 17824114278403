.slick-slide img {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  opacity: 0.6; /* none centered slides with opacity */
}

.slick-slide.slick-center img {
  transform: scale(1.5); /* resize the central element */
  opacity: 1; /* central element with full opacity */
}

.carousel-image {
  width: 60%;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  cursor: pointer;
}

.slick-list {
  height: 500px;
  align-content: center;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  cursor: pointer;
}

.next-arrow {
  right: 0; /* Ajusta la posición de la flecha derecha */
}

.prev-arrow {
  left: 0; /* Ajusta la posición de la flecha izquierda */
}

.custom-arrow img {
  width: 80px; /* Ajusta el tamaño de las imágenes de flechas */
  height: 80px;
}

/* Small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .next-arrow {
    right: 10px;
  }

  .prev-arrow {
    left: 10px;
  }

  .custom-arrow img {
    width: 120px;
    height: 120px;
  }
}

@media only screen and (min-width: 1000px) {
  .custom-arrow img {
    width: 150px;
    height: 150px;
  }
  .slick-slide.slick-center img {
    transform: scale(1.8);
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .recipes-slider {
    width: 80%;
  }
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (min-width: 1600px) {
}
