.product-detail {
  position: relative;
}
.product-detail-section {
  background-image: url("../../../../assets/images/BG_Product_Detail_Desktop.png");
  background: top;
  /* background-size: cover; */
  height: 739px;
  padding-top: 50px;
  background-color: #1d0f3e;
  color: white;
}

.product-detail .spice-level-section {
  background-image: url("../../../../assets/images/bg_spice_level.png");
  height: 469px;
  position: relative;
}

.product-detail .icon-modal:hover {
  filter: drop-shadow(0px 0px 20px #FF652D) drop-shadow(0px 0px 20px #FF652D);
}

.product-detail .icon-section {
  right: 10px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  position: absolute;
  justify-content: end;
}

.product-detail .icon-section img {
  cursor: pointer;
}

.product-detail .product-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product-detail .details-section {
  flex-direction: column;
  margin-left: 15px;
  width: 300px;
}
.product-detail .product-title {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
  margin: 0;
}
.product-detail .product-spice {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  margin-top: 5px;
}

.product-detail .product-subtitle {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: bold;
  margin-top: 30px;
}

.product-detail .product-description {
  font-size: 18px;
  line-height: 1.5;
  margin-top: 5px;
}

.product-detail .imageProd-section {
  display: flex;
  justify-content: center;
  width: 600px;
}

.product-detail .product-image {
  width: 300px;
  height: auto;
  padding: 10px;
}

.product-detail .image-grid-container {
  padding: 20px;
}

.product-detail .image-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 5px;
}

.product-detail .image-item {
  position: relative;
  text-align: center;
}

.product-detail .image-wrapper {
  position: relative;
  width: 100%;
  height: auto;
}

.product-detail .main-image {
  width: 90px;
  height: auto;
}

.product-detail .arrow-image {
  position: absolute;
  bottom: 50px;
  right: 5px;
  width: 80px;
  height: 80px;
  top: 110px;
}

.product-detail .spicyLine-container {
  width: 100%;
  margin-top: 20px;
}

.product-detail .spicyLine {
  width: 100%;
  height: auto;
}

.product-detail .icon-modal {
  height: 40px;
}

.product-detail .separator {
  height: 30px;
  position: absolute;
  bottom: 0;
}

.spice-level-section .parallax-image-container.center.bottom {
  z-index: 10;
  width: 100%;
}
.spice-level-section .triangle-center {
  width: 70px;
}

.recipes-section {
  margin-top: 1rem;
}

.recipe-item {
  text-align: center;
}

.recipe-image {
  width: 100%;
  max-width: 90px; /* Tamaño de la imagen de la receta */
  border-radius: 8px;
}

.no-recipes-message {
  color: #666; /* Color del mensaje si no hay recetas */
}

.recipes-section li:hover {
  filter: drop-shadow(0px 0px 10px #FDE003) drop-shadow(0px 0px 10px #FEAE01) drop-shadow(0px 0px 10px #FDE003);
}

.recipes-section li::before {
  content: "•";
  color: orange;
  margin-right: 5px;
}


/* Small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
  .product-detail .details-section {
    margin: 0 30px;
    width: 380px;
  }
  .product-detail .icon-section {
    right: 20px;
  }
  .product-detail .product-image {
    width: 400px;
    height: auto;
    padding: 10px;
  }
  .product-detail .spice-level-section {
    height: 300px;
  }
  .product-detail .image-grid {
    margin: 0 30px;
  }
  .product-detail .imageProd-section {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .product-detail .details-section {
    margin-left: 50px;
    width: 550px;
  }
  .product-detail .icon-modal {
    height: 50px;
  }
  .product-detail .product-image {
    width: 400px;
    height: auto;
    padding: 10px;
  }
}

@media only screen and (min-width: 1000px) {
  .product-detail-section {
    background-size: cover;
    height: 739px;
    padding-top: 50px;
  }
  .product-detail .icon-section {
    right: 50px;
    display: flex;
    flex-flow: row;
    gap: 15px;
  }

  .product-detail .details-section {
    margin-left: 50px;
    width: 50%;
  }

  .product-detail .product-title {
    font-size: 38px;
    margin: 0;
  }
  .product-detail .product-spice {
    font-size: 22px;
    margin-top: 10px;
  }
  .product-detail .product-subtitle {
    text-transform: uppercase;
    font-size: 38px;
    font-weight: bold;
    margin-top: 40px;
  }

  .product-detail .product-description {
    font-size: 22px;
    line-height: 1.5;
    margin-top: 10px;
  }
  .product-detail .product-content {
    flex-wrap: nowrap;
  }

  .product-detail .imageProd-section {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .product-detail .product-image {
    height: auto;
    padding: 10px;
    width: 550px;
  }
  .product-detail .main-image {
    width: 140px;
    height: auto;
  }
  .product-detail .spice-level-section {
    height: 430px;
  }
  .product-detail .arrow-image {
    right: 15px;
    width: 100px;
    height: 100px;
    top: 170px;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .product-detail .product-title {
    font-size: 48px;
  }
  .product-detail .product-spice {
    font-size: 24px;
  }
  .product-detail .product-subtitle {
    font-size: 48px;
  }
  .product-detail .product-description {
    font-size: 24px;
  }
}

@media only screen and (min-width: 1400px) {
  .product-detail .image-grid {
    margin: 0 80px;
  }
  .product-detail .product-image {
    width: 700px;
  }
  .product-detail .details-section {
    margin-left: 100px;
  }
}

@media only screen and (min-width: 1600px) {
}
