.contact-section {
  align-items: center;
  background-image: url("../../../assets/images/bg_contact.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
  display: flex;
  height: 706px;
  justify-content: center;
  position: relative;
}
.contact-content {
  position: relative;
}

.contact-title {
  display: flex;
  font-size: 26px !important;
  font-weight: bolder !important;
  padding-left: 15px;
  text-align: center;
  text-transform: uppercase;
}
.map-section {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
}
.map-image {
  width: 100%;
  z-index: 20;
}
.contact-title,
.address-section {
  color: #fff;
  margin: 0 20px;
}

.address-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 15px;
}

.contact-section .parallax-image-container.center {
  top: 40%;
  z-index: 10;
}
.contact-section .parallax-image-container.top {
  top: 10%;
  width: 100%;
}
.contact-section .triangle-center {
  width: 50px;
}
.contact-section .triangle-top {
  transform: rotate(60);
}
.contact-section .triangle-bottom {
  width: 100px;
}

/* Small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
  .contact-section .parallax-image-container.center {
    top: 45%;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .contact-section {
    background-position: bottom right;
    height: 850px;
  }
  .address-section {
    font-size: 18px;
    padding-left: 50px;
  }
  .map-section {
    width: 100%;
    margin: 0 80px;
  }
  .contact-section .parallax-image-container.center {
    top: 55%;
    left: 5%;
  }
}

@media only screen and (min-width: 1000px) {
  .contact-section {
    align-items: baseline;
  }
  .contact-content {
    margin-top: 120px;
  }
  .contact-title {
    display: flex;
    font-size: 40px !important;
    text-align: left;
    padding-left: 5px;
  }
  .contact-title,
  .address-section {
    color: #fff;
    margin: 20px;
  }
  .map-section {
    width: 40%;
    margin: 0 50px;
  }
  .address-section {
    width: 40%;
    justify-content: flex-end;
  }
  .map-image {
    width: 480px;
  }
  .contact-section .parallax-image-container.center {
    top: 50%;
    left: 50%;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .contact-section {
    background-position: center bottom;
  }
  .map-image {
    width: 520px;
  }
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (min-width: 1600px) {
}
