.parallax-image-container {
  position: absolute;
  display: flex;
}

.parallax-image-container img {
  cursor: pointer;
}

.parallax-image-container.left {
  left: 10px;
}
.parallax-image-container.right {
  right: 10px;
}
.parallax-image-container.top {
  top: 10px;
}
.parallax-image-container.bottom {
  bottom: 10px;
}
.parallax-image-container.center {
  text-align: center;
  justify-content: center;
}
.parallax-horizontal {
  transform: rotate(90deg);
}

.rotating-image {
  transition: transform 0.1s linear;
  animation: rotateOnScroll 2s linear infinite;
  animation-play-state: paused;
}

body {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

@keyframes rotateOnScroll {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation-play-state: running;
}

/* Hover effect */
.zoom:hover {
  animation: zoom 1s infinite;
}

/* ZoomIn and ZoomOut animation */
@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
