.footer-container {
  background-image: url("../../assets/images/BG_Footer.png");
  background-size: cover;
  background-position: center left;
  bottom: 0;
  color: white;
  padding: 20px 0;
  position: absolute;
  text-align: center;
  height: 810px;
  width: 100%;
  z-index: 2;
}

.footer-title {
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
  display: inline-table;
  width: 250px;
}

.footer-text {
  list-style: none;
  line-height: 1.5 !important;
  font-size: 13px !important;
  width: 250px;
  text-align: left;
  padding-top: 30px;
  padding-left: 0px;
}

.footer-text li:hover {
  filter: drop-shadow(0px 0px 10px #FDE003) drop-shadow(0px 0px 10px #FEAE01) drop-shadow(0px 0px 10px #FDE003);
  cursor: pointer;
}

.footer-text li::before {
  content: "•";
  color: orange;
  margin-right: 10px;
}

.footer-svg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
}
.footer-content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  top: 65px;
  gap:110px
}
.text-author {
  align-self: center;
  font-size: 12px !important;
  margin-left: 20px;
}
.footer-container .social-icons {
  display: flex;
  gap: 10px; /* Espacio entre los iconos */
}
.footer-container .social-icon {  
  width: 25px;
  height: 25px;
  transition: all 0.3s ease;
  background-size: contain;
}
.subscribe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.subscribe-input {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px 0 0 5px;
  width: 70%;
  outline: none;
}
.subscribe-button {
  background-color: black;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
  display: flex;
  align-items: center;
}
.subscribe-button:hover {
  background-color: #333;
}


@media only screen and (min-width: 425px) {
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 1000px) {
  .footer-container {
    background-position: top;
    height: 350px;
  }

  .css-bpwut1-MuiGrid2-root {
    --Grid-columnSpacing: 0px !important;
  }
  
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (min-width: 1600px) {
}
