.mark-footer {
  height: 150px;
  background-image: url("../../assets/images/BG_Mark_Footer.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.mark-footer .logo-container {
  width: auto;
}

.mark-footer .logo {
  width: auto;
  height: 35px;
  margin: 15px;
}

.mark-footer .svg-container-left {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  gap: 10px; /* Espaciado entre los SVGs */
  width: 200px;
}

.mark-footer .svg-container-right {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 10px; /* Espaciado entre los SVGs */
  width: 200px;
}

.mark-footer .svg-container-left img,
.mark-footer .svg-container-right img {
  width: 20px;
  height: auto;
}

@keyframes shadowShortCircuit {
  60%, 80% {
    filter: drop-shadow(0px 0px 10px #FDE003) drop-shadow(0px 0px 10px #FEAE01) drop-shadow(0px 0px 10px #FDE003);
  }
  90% {
    filter: drop-shadow(0px 0px 25px #FDE003) drop-shadow(0px 0px 25px #FEAE01) drop-shadow(0px 0px 25px #FDE003);
  }
}

.arrowShadow {
  filter: drop-shadow(0px 0px 20px #FDE003) drop-shadow(0px 0px 20px #FEAE01) drop-shadow(0px 0px 20px #FDE003);
  animation: shadowShortCircuit 3s infinite;
}

@media only screen and (min-width: 425px) {
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 1000px) {
  .mark-footer {
    height: 200px;
  }
  .mark-footer .logo {
    width: auto;
    height: 80px;
    margin: 15px;
  }
  .mark-footer .svg-container-left,
  .mark-footer .svg-container-right {
    width: 300px;
    gap: 25px;
  }

  .mark-footer .svg-container-left img,
  .mark-footer .svg-container-right img {
    width: 40px;
    height: auto;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1400px) {
  .mark-footer {
    height: 250px;
  }
  .mark-footer .logo {
    width: auto;
    height: 100px;
    margin: 15px;
  }

  .mark-footer .svg-container-left,
  .mark-footer .svg-container-right {
    width: 400px;
  }

  .mark-footer .svg-container-left img,
  .mark-footer .svg-container-right img {
    width: 50px;
    height: auto;
  }
}

@media only screen and (min-width: 1600px) {
}
