.easter-egg {
  z-index: 9999;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.easter-egg_title {
  font-size: 24px !important;
  font-weight: bold !important;
  color: #fff;
  margin-top: -60px !important;
  animation: fadeIn 0.8s ease-in-out;
}

.easter-egg_subtitle {
  font-size: 26px !important;
  font-weight: bold !important;
  color: #fff;
  margin-top: 80px !important;
  animation: fadeIn 0.8s ease-in-out;
}

/* Small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .next-arrow {
    right: 10px;
  }

  .prev-arrow {
    left: 10px;
  }

  .custom-arrow img {
    width: 120px;
    height: 120px;
  }
  .easter-egg_title {
    font-size: 32px !important;
  }
}

@media only screen and (min-width: 1000px) {
  .easter-egg_title {
    font-size: 38px !important;
    margin-top: 60px !important;
  }
  .easter-egg_subtitle {
    margin-top: 0 !important;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .recipes-slider {
    width: 80%;
  }
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (min-width: 1600px) {
}
