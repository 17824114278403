body {
  background-color: #000000;
}
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

@font-face {
  font-family: "Secular One", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.content-centered {
  justify-content: center;
  display: flex;
}
