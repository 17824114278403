.divider-container {
  position: absolute;
  z-index: 25;
  width: 100%;
  bottom: -15px;
}
.divider-img {
  width: 100%;
  height: auto;
}

/* Small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
  .divider-container {
    bottom: -35px;
  }
  .divider-img {
    height: 60px;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
}

@media only screen and (min-width: 1000px) {
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1400px) {
  .divider-container {
    bottom: -60px;
  }
  .divider-img {
    height: auto;
  }
}

@media only screen and (min-width: 1600px) {
}
