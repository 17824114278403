.header {
  background: #190324;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 7rem;
  z-index: 1;
}

.header.logo {
  height: auto;
  width: 70px;
}

.header .menu {
  display: none;
}

.header .social-icon {
  width: 30px;
  height: 30px;
  transition: all 0.3s ease;
  background-size: contain;
}
.social-icon.facebook {
  background-image: url("../../assets/images/facebook.png");
  box-shadow: 0px 0px 6.1px 0px rgba(75, 75, 75, 0.5);
}
.social-icon.facebook:hover {
  background-image: url("../../assets/images/facebook_hover.png");
  box-shadow: 0px 1px 36.2px -10px #fde003;
}
.social-icon.tiktok {
  background-image: url("../../assets/images/tiktok.png");
  box-shadow: 0px 0px 6.1px 0px rgba(75, 75, 75, 0.5);
}
.social-icon.tiktok:hover {
  background-image: url("../../assets/images/tiktok_hover.png");
  box-shadow: 0px 1px 36.2px -10px #fde003;
}
.social-icon.youtube {
  background-image: url("../../assets/images/youtube.png");
  box-shadow: 0px 0px 6.1px 0px rgba(75, 75, 75, 0.5);
}
.social-icon.youtube:hover {
  background-image: url("../../assets/images/youtube_hover.png");
  box-shadow: 0px 1px 36.2px -10px #fde003;
}
.social-icon.instagram {
  background-image: url("../../assets/images/Instagram.png");
  box-shadow: 0px 0px 6.1px 0px rgba(75, 75, 75, 0.5);
}
.social-icon.instagram:hover {
  background-image: url("../../assets/images/Instagram_hover.png");
  box-shadow: 0px 1px 36.2px -10px #fde003;
}
.section-svg {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-82%);
  width: 137px;
  height: 90px;
}
.svg-container {
  position: relative;
}

.menu-mobile-img {
  width: 50px;
  margin-right: 10px;
}

.MuiDrawer-paper {
  background-color: #190324 !important;
}
.menu-mobile-options {
  color: #fff;
  margin-left: 20px;
}
.menu-mobile-logo {
  margin: 20px;
}
.logo.logo--mobile {
  width: 100px;
}

@media only screen and (min-width: 425px) {
  .header .logo {
    height: auto;
    width: 80px;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .header .logo {
    margin: 15px;
    width: 150px;
  }
  .header .social-icon {
    width: 40px;
    height: 40px;
  }
  .menu-mobile-img {
    width: 80px;
    margin-right: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .header .logo {
    margin: 10px;
    width: 120px;
  }
  .header .menu {
    display: block;
  }
  .header .social-icon {
    width: 35px;
    height: 35px;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .header .logo {
    margin: 15px;
    width: 150px;
  }
  .header .social-icon {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (min-width: 1400px) {
}

@media only screen and (min-width: 1600px) {
}
