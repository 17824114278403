.products-slider-container {
  background-image: url("../../../../assets/images/bg_products.png");
  height: auto;
  padding-top: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.btn-vermas img {
  width: 200px;
}

.products .slick-slide.slick-center img {
  transform: scale(1.2);
  opacity: 1;
}

.products-slider-container .triangle-top {
  transform: rotate(20deg);
  width: 60px;
}
.products-slider-container .parallax-image-container.top {
  top: 30px;
  z-index: 35;
}

.products-slider-container .parallax-image-container.right {
  transform: rotate(20deg);
  right: 60px;
}

.products-slider-container .triangle-right {
  width: 50px;
}

/* Small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .products .slick-slide.slick-center img {
    transform: scale(0.6);
  }
  .products-slider-container .parallax-image-container.top {
    top: 60px;
  }
  .products-slider-container .triangle-top {
    width: 100px;
  }

  .products-slider-container .parallax-image-container.right {
    right: 160px;
  }
  .products-slider-container .triangle-right {
    width: 80px;
  }
}

@media only screen and (min-width: 1000px) {
  .products-slider-container {
    padding-top: 0;
    background-size: 100%;
    height: 560px;
  }
  .products .slick-slide.slick-center img {
    transform: scale(1.8);
  }
  .products-slider {
    padding-top: 50px;
  }
  .products-slider-container .parallax-image-container.top {
    top: 20px;
  }
  .products-slider-container .triangle-top {
    width: 150px;
  }
  .products-slider-container .parallax-image-container.right {
    right: 100px;
  }
  .products-slider-container .triangle-right {
    width: 150px;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

@media only screen and (min-width: 1400px) {
  .products-slider-container {
    height: 750px;
  }
  .products-slider {
    padding-top: 150px;
  }
}

@media only screen and (min-width: 1600px) {
}
