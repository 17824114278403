.animated-bowl-container {
    background-image: url("../../assets/images/bg_bowl.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
}

.animated-bowl-container .triangle-right {
    width: 70px;
}
.animated-bowl-container .parallax-image-container.right {
    z-index: 35;
}