.video-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 20;
}

video {
  width: 100%;
  height: 100%;
  display: block;
}

.play-pause-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  z-index: 2;
  display: none; /* Oculto por defecto */
}

.video-container .play-pause-btn:not(.always-visible) {
  display: none; /* Mostrar el botón solo en hover cuando el video está en reproducción */
}

.play-pause-btn.always-visible {
  display: block; /* Siempre visible cuando está en pausa o terminado */
}

.icon-image {
  width: 40px;
  height: 40px;
}

@media only screen and (min-width: 425px) {
  .icon-image {
    width: 100px;
    height: 100px;
  }
}
