.image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.title {
  padding: 30px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}

.experience-section {
  background-image: url("../../../assets/images/BACK_TOPEXP.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  height: 350px;
  position: relative;
}

.img-experience {
  width: 150px;
  z-index: 10;
}

.sub-section {
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.experience-button {
  margin: 20px !important;
  border-radius: 12px !important;
  border: 3px solid rgb(28, 189, 27) !important;
  color: rgb(28, 189, 27) !important;
}

.experience-button--white {
  border: 3px solid #fff !important;
  color: #fff !important;
}

.experience-section .triangle-left {
  width: 50px;
}
.experience-section .triangle-right {
  width: 80px;
}
.experience-section .triangle-center {
  width: 40px;
}
.experience-section .parallax-image-container.center {
  top: 50%;
  z-index: 10;
  width: 100%;
}

@media only screen and (min-width: 425px) {
  .img-experience {
    width: 180px;
  }
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .experience-section {
    height: 450px;
  }
  .img-experience {
    width: 300px;
  }
  .experience-section .triangle-left {
    width: 80px;
  }
  .experience-section .triangle-right {
    width: 150px;
  }
  .experience-section .triangle-center {
    width: 60px;
  }
}

@media only screen and (min-width: 1000px) {
  .experience-section {
    height: 500px;
  }
  .img-experience {
    width: 350px;
  }
  .experience-section .triangle-left {
    width: 230px;
  }
  .experience-section .triangle-right {
    width: 200px;
  }
  .experience-section .triangle-center {
    width: 100px;
  }

}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sections {
    margin-top: 25px;
  }
}

@media only screen and (min-width: 1400px) {
  .experience-section {
    height: 600px;
  }
  .img-experience {
    width: 400px;
  }
}

@media only screen and (min-width: 1600px) {
}
