.recipe-detail-section {
  background-image: url("../../../../assets/images/bg_recipe_details.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #1d0f3e;
  color: white;
  height: auto;
  padding-bottom: 80px;
  padding-top: 50px;
  position: relative;
}

.recipe-detail-section .recipe-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recipe-detail-section .details-section {
  flex-direction: column;
  margin: 0 30px;
  width: 100%;
}

.recipe-detail-section .recipe-title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  margin: 0;
}

.recipe-detail-section .recipe-descrip {
  margin-top: 20px;
  font-size: 16px;
}

.recipe-detail-section .extra-info {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  flex-direction: column;
}

.recipe-detail-section .info-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.recipe-detail-section .product-image {
  width: 100%;
  margin: 0 auto;
}

.recipe-detail-section .recipe-steps {
  margin-top: 50px;
}

.recipe-detail-section .section-title {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.recipe-detail-section .ingredients-list,
.recipe-detail-section .preparation-list {
  list-style: none;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  padding: 0 20px;
}

.recipe-detail-section .ingredients-list li::before {
  content: "•";
  color: orange;
  margin-right: 10px;
}

.recipe-detail-section .preparation-list li::before {
  content: "•";
  color: orange;
  margin-right: 10px;
}

.recipe-detail-section .preparation-list li {
  margin-bottom: 10px;
}

.recipe-detail-section .section-steps {
  width: 700px;
  text-align: center;
}

.recipe-detail-section .recipe-descrip {
  font-weight: 900;
}

.recipe-detail-section .parallax-image-container.left.top {
  top: -30px;
  z-index: 10;
}
.recipe-detail-section .triangle-center {
  width: 70px;
}


/* Small devices (phones, 425px and up) */
@media only screen and (min-width: 425px) {
}

/* Small devices (portrait tablets and large phones, 768px and up) */
@media only screen and (min-width: 700px) {
  .recipe-detail-section .extra-info {
    flex-direction: row;
  }
  .recipe-detail-section .recipe-title {
    font-size: 38px;
  }
  .recipe-detail-section .section-title {
    font-size: 38px;
  }
  .recipe-detail-section .ingredients-list,
  .recipe-detail-section .preparation-list {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1000px) {
  .recipe-detail-section {
    padding-top: 80px;
    background-position: top;
  }
  .recipe-detail-section .details-section {
    width: 400px;
  }
  .recipe-detail-section .recipe-title {
    font-size: 42px;
  }
  .recipe-detail-section .section-steps {
    width: 45%;
  }
  .recipe-detail-section .ingredients-list,
  .recipe-detail-section .preparation-list {
    padding: 0 30px;
    font-size: 18px;
  }
  .recipe-detail-section .product-image {
    width: 400px;
  }
  .recipe-detail-section .section-title {
    font-size: 36px;
  }
}

/* Large devices (laptops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .recipe-detail-section {
    padding-top: 100px;
  }
  .recipe-detail-section .details-section {
    width: 40%;
  }
  .recipe-detail-section .recipe-title {
    font-size: 52px;
  }
  .recipe-detail-section .ingredients-list,
  .recipe-detail-section .preparation-list {
    padding: 0 50px;
  }
  .recipe-detail-section .product-image {
    width: 400px;
  }
  .recipe-detail-section .section-title {
    font-size: 36px;
  }
  .recipe-detail-section .recipe-steps {
    margin-top: 100px;
  }
}

@media only screen and (min-width: 1400px) {
  .recipe-detail-section .details-section {
    width: 40%;
  }
  .recipe-detail-section .recipe-title {
    font-size: 64px;
  }
  .recipe-detail-section .recipe-descrip {
    font-size: 20px;
  }
  .recipe-detail-section .ingredients-list,
  .recipe-detail-section .preparation-list {
    padding: 0 50px;
    font-size: 20px;
  }
  .recipe-detail-section .product-image {
    width: 500px;
  }
  .recipe-detail-section .section-title {
    font-size: 42px;
  }
  .recipe-detail-section .recipe-steps {
    margin-top: 120px;
  }
}

@media only screen and (min-width: 1600px) {
}
