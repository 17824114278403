.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 310px;
  background: linear-gradient(300deg, #fef216, #fda502);
  border-radius: 10px;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0px 0px 114.61px 0px #C6A324, 0px 0px 65.491px 0px #C6A324, 0px 0px 38.203px 0px #C6A324, 0px 0px 19.102px 0px #C6A324, 0px 0px 5.458px 0px #C6A324, 0px 0px 2.729px 0px #C6A324;
}

.modal-title {
  font-weight: bolder !important;
  color: #ffffff;
  margin-bottom: 16px;
}

.nutrition-table {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 10px;
}

.ingredients-table {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  padding: 16px;
}

.table-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.table-label {
  font-size: 0.875rem;
}

.table-value {
  font-size: 0.875rem;
}

.bold {
  font-weight: bold;
}
